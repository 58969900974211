@import '../../assets/css/breakpoints.scss';


.container {
    text-align: center;
    width: 100%;
    max-width: 500px;
    border-radius: 12px;
    padding: 24px;
    background-color: var(--primary-color);
    z-index: 500;
    box-shadow: 2px 2px 20px var(--primary-color);
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
            font-size: 20px;
        }
        img {
            width: 16px;
            height: 16px;
        }
    }
    .body {
        .walletItem {
            border-radius: 6px;
            // border: 1px solid #31343e;
            background-color: var(--secondary-color);
            padding: 17px;
            margin-top: 20px;
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
            .walletLabel {
                font-size: 16px;
            }
            .walletIcon {
                width: 27px;
                // height: 27px;
            }
        }
    }
}
    