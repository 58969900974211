@import '../../assets/css/breakpoints.scss';

.horizontal-slider {
  width: 100%;
  // max-width: 90%;
  height: 70px;
  // border: 1px solid grey;
}

.example-thumb {
  font-size: 21px;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  color: #63C127;
  cursor: pointer;
  border: 3px solid #63C127;
  border-radius: 50px;
  box-sizing: border-box;
  outline: none;
}

// .example-thumb.active {
//   background-color: grey;
// }

.example-track {
  position: relative;
  background: #63C127;
}

.example-track.example-track-1 {
  background: #63C127;
}

.horizontal-slider .example-track {
  top: 35px;
  height: 3px;
}

.horizontal-slider .example-thumb {
  top: 1px;
  width: 70px;
  height: 70px;
  line-height: 38px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.rounded-default {
  border-radius: 30px;
}

.popup-container {
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(142, 144, 144, 0.2);
  z-index: 3000;
}

.custom-select {
  border-radius: 100px;
  border: 1px solid #8E9090;
  overflow: hidden;
  padding: 2px 0;
  select {
      background: url(../images/chevron-white.svg) no-repeat 98%;
      background-size: 16px;
      -webkit-appearance: none;
      background-color: var(--primary-color);
      color: #8E9090;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.7);
  z-index: -1;
}

/* hide scrollbar but allow scrolling */
.non-scrollbar-box {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.non-scrollbar-box::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

li {
  padding: 4px 0 4px 20px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}