@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --primary-color: #1C2021;
  --secondary-color: #272833;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--primary-color);
  color: white;
}
/* a {
  text-decoration: none;
  color: #2C6B78;
}
p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
} */
